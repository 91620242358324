<template>
  <div>
    <div class="ml-4 pl-3">
      <back-button/>
    </div>
    <div v-loading="loaders.resource" class="container-fluid mt-3 row ">
      <h1 class="d-inline-block my-4 col-12">On Demand Message</h1>
      <div class="col-md-6">
        <card class="no-border-card d-flex justify-content-start align-items-lg-start">
          <div class="card-title">
            <h2 class="ondemand-detail-heading">MESSAGE</h2>
          </div>
          <div class="card-body p-0">
            <p class="ondemand-message-text">{{ response.onDemandMessage.message }}</p>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <card class="no-border-card">
          <div v-if="response.onDemandMessage.stage">
            <div class="card-title">
              <h2 class="ondemand-detail-heading">STAGES</h2>
            </div>
            <div class="card-body p-0">
              <div class="row">
                <div class="col-6 pb-2" v-for="stage in response.onDemandMessage.stage">
                  <span class="ondemand-message-text">{{ stage }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="(category,index) in response.categories" v-if="category.length">
              <div class="card-title">
                <h2 class="ondemand-detail-heading">PATIENT TAG ({{ category[0].name }})</h2>
              </div>
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-6 pb-2" v-for="subCategory in category[0].user_sub_categories">
                    <span class="ondemand-message-text">{{ subCategory.name }}</span>
                  </div>
                </div>
              </div>
              <span v-if="index!==response.categories.length-1" class="d-block line w-100 mt-1 mb-3"></span>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>

</template>

<script>
import BackButton from "@/components/Router/BackButton";

export default {
  name: "ShowOnDemandMessage",
  components: {
    BackButton,
  },
  data() {
    return {
      loaders: {
        resource: false,
      },
      onDemandMessageId: this.$route.params.id,
      stages: {
        25: 'Considering Surgery',
        50: 'Pre-Op',
        75: 'Post-Op',
        150: 'Non-Surgical',
      },
      response: {
        onDemandMessage: {},
        categories: {},
        stage: {}
      },
      onDemandMessage: {
        message: '',
        stage: '',
        date: new Date().toISOString().split('T')[0],
      },
      categories: []
    };
  },
  mounted() {
    this.getOnDemandMessage()
  },
  methods: {
    /**
     * Get the requested ondemand message
     */
    getOnDemandMessage() {
      let vm = this;
      vm.loaders.resource = true;
      const payload = {
        id: vm.onDemandMessageId
      }
      vm.$store.dispatch('_fetchOnDemandMessage', payload)
        .then(response => {
          vm.response.onDemandMessage = response.data.data.onDemandNotification
          if (response.data.data.onDemandNotification.stage) {
            const stages = response.data.data.onDemandNotification.stage.split(',')
            vm.response.onDemandMessage.stage = stages.map(function (x) {
              return vm.stages[x]
            })
          } else {
            vm.response.categories = response.data.data.categories
          }
        })
        .catch(error => {
          let message = error.response?.data?.message ?? 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'On Demand Message',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.resource = false;
        })
    },
  }
}

</script>

<style scoped>

.line {
  border: 1px solid #00000042;
  opacity: 0.36;
}

.ondemand-detail-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}

.ondemand-message-text {
  text-align: left;
  word-break: break-word;
  font: normal normal normal 14px/26px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

.no-border-card {
  border-top: 0;
}

</style>
