<template>
  <div>
    <group-message-dialog
      :dialogVisible="groupMessageModal"
      :categories="response.tags"
      @updateMessages="updateMessages"
      @closeDialog="toggleGroupMessageModal">
    </group-message-dialog>
    <div class="m-0 pl-3 pt-4 pb-0 pr-2 d-flex justify-content-between align-items-center">
      <h1 class="ml-3 d-inline-block mt-2">On Demand</h1>
      <base-button
        size="md"
        @click="openGroupMessageDialog"
        class="mr-4 mt-2 title-btn-padding btn-secondary-action button-font"
        type="secondary">
        Send Group Message
      </base-button>
    </div>
    <base-header class="base-header-height">
    </base-header>
    <div v-loading="loaders.table" class="container-fluid mt--8 mb-5">
      <div class="content mt-3">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div class="row">
            <div class="col-12 d-flex">
              <div class="table-responsive">
                <el-table :data="response.onDemandMessages"
                          row-key="id"
                          class="table-responsive table-flush"
                          header-row-class-name="thead-light"
                >
                  <el-table-column label="MESSAGE" prop="message" min-width="130px">
                    <template slot-scope="scope">
                      <div class="trim-text-overflow">{{ scope.row.message }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="STAGES" min-width="80px">
                    <template v-if="scope.row.stages" slot-scope="scope">
                      <div v-if="scope.row.stages.length > 1">
                        <span>{{ scope.row.stages[0] }},</span>
                        <el-popover
                          placement="bottom-end"
                          trigger="hover"
                          :visible-arrow="false"
                          width="fit-content"
                        >
                          <span slot="reference" class="tooltip">
                            +{{ scope.row.stages.length - 1 }} more
                          </span>
                          <span v-for="(item,index) in scope.row.stages">
                            <span class="tooltip-text" v-if="index !== 0">
                              {{ item }}
                            </span>
                          </span>
                        </el-popover>
                      </div>
                      <div v-else>
                        <span v-for="item in scope.row.stages">{{ item }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="PATIENT TAG" min-width="65px">
                    <template v-if="scope.row.subCategories" slot-scope="scope">
                      <div v-if="scope.row.subCategories.length > 1">
                        <span class="trim-text-overflow">{{ scope.row.subCategories[0].name }} ,</span>
                        <el-popover
                          placement="bottom-end"
                          trigger="hover"
                          :visible-arrow="false"
                          width="fit-content"
                        >
                          <span slot="reference" class="tooltip">
                            +{{ scope.row.subCategories.length - 1 }} more
                          </span>
                          <span v-for="(item,index) in scope.row.subCategories">
                            <span class="tooltip-text" v-if="index !== 0">
                              {{ item.name }}
                            </span>
                          </span>
                        </el-popover>
                      </div>
                      <div v-else>
                        <span v-for="item in scope.row.subCategories">{{ item.name | handleLongString }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="DATE" prop="date" min-width="62px">
                    <template slot-scope="scope">
                      {{ scope.row.date | getOnDemandMessageDate }}
                    </template>
                  </el-table-column>
                  <el-table-column label="ACTIONS" width="150px">
                    <div slot-scope="scope" class="d-flex">
                      <router-link :to="{name: 'ShowOnDemandMessage', params: {id: scope.row.id}}">
                        <img class="pointer ml-2" src="/img/icons/buttons/show.svg" alt="Show Message Details">
                      </router-link>
                    </div>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <pagination-footer :pagination="pagination" v-model="pagination.page" @changedPageLength="changedPageLength"/>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import GroupMessageDialog from "@/views/Components/Patients/GroupMessageDialog"
import PaginationFooter from "@/views/Components/Pagination/PaginationFooter"
import {Option, Select, Table, TableColumn} from "element-ui"
import moment from "moment"

export default {
  name: "OnDemandMessages",
  components: {
    PaginationFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    GroupMessageDialog
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      loaders: {
        table: false
      },
      response: {
        onDemandMessages: [],
        stages: [],
        tags: {}
      },
      groupMessageModal: false,
      onDemandMessage: {
        form: {
          message: '',
          date: '',
          stages: [],
          subCategories: {}
        },
      },
      pagination: {
        currentPage: 1,
        perPage: 20,
        page: 1,
        lastPage: 1,
        total: 0
      },
    }
  },
  mounted() {
    this.getOnDemandMessages()
    this.fetchTags()
  },
  filters: {
    /**
     * Reformat the date into MMM DD, YYYY
     * @param date
     * @returns {string}
     */
    getOnDemandMessageDate: function (date) {
      return moment(date).format('MMMM DD, YYYY')
    },

    /**
     * Handle long string exceeding limit and append ...
     * @param longString
     * @param limit
     * @returns {string|string|*}
     */
    handleLongString: function (longString, limit = 15) {
      return (longString === null) ? '' : (longString.length > limit) ? longString.slice(0, limit) + '...' : longString
    },
  },
  methods: {
    /**
     * Change page from the list
     */
    changePage() {
      this.getOnDemandMessages()
    },

    /**
     * Change page length for show
     * @param pageLength
     */
    changedPageLength(pageLength) {
      this.pagination.perPage = pageLength
      this.getOnDemandMessages()
    },

    /**
     * Get All the Patient Tags
     */
    fetchTags() {
      let vm = this
      vm.loaders.modal = true
      vm.$store.dispatch('_fetchTags')
      .then(response => {
        vm.response.tags = response.data.data
      })
      .catch(error => {
        let message = error.response?.data?.message ?? 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'On Demand Messages',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.modal = false
      })
    },

    /**
     * Get all the OnDemandMessages
     */
    getOnDemandMessages() {
      let vm = this
      vm.loaders.table = true
      const payload = {
        page: vm.pagination.page,
        perPage: vm.pagination.perPage
      }
      vm.$store.dispatch('_getOnDemandMessages', payload)
      .then(response => {
        vm.response.onDemandMessages = [...response.data.data.data]
        vm.pagination.currentPage = response.data.data.current_page
        vm.pagination.lastPage = response.data.data.last_page
        vm.pagination.perPage = response.data.data.per_page
        vm.pagination.page = response.data.data.current_page
        vm.pagination.total = response.data.data.total
      })
      .catch(error => {
        if(!axios.isCancel(error)) {
          let message = error.response?.data?.message ?? 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'On Demand Messages',
            message: message
          })
        }
      })
      .finally(() => {
        vm.loaders.table = false
      })
    },

    /**
     * Show the Group Message Dialog
     */
    openGroupMessageDialog() {
      this.groupMessageModal = true
    },

    /**
     * Close the group message dialog and update new messages
     */
    updateMessages() {
      this.toggleGroupMessageModal()
      this.getOnDemandMessages()
    },

    /**
     * Toggle the Group Message Modal
     */
    toggleGroupMessageModal() {
      this.groupMessageModal = !this.groupMessageModal
    }
  },

  watch: {
    'pagination.page' : function() {
        this.changePage();
    }
  }
}
</script>

<style scoped>

.tooltip {
  display: inline-block;
  opacity: 1;
  color: #832C44;
  margin-top: 2px;
  padding-left: 5px;
  height: 18px;
  z-index: inherit;
}

.tooltip-text {
  display: block;
  font: normal normal 600 12px/20px Open Sans;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #000D18;
  background: #FFFFFF 0 0 no-repeat padding-box;
}

.tooltip:hover {
  visibility: visible;
}

.base-header-height {
  height: 130px
}

</style>
