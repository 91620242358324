<template>
  <div>

    <el-dialog
      title="Send Group Message"
      :visible.sync="dialogVisible"
      width="524px"
      :before-close="handleClose"
    >

      <el-tabs
        type="card"
        class="dialog-tabs"
        @tab-click="handleTabClick"
      >
        <el-tab-pane
          label="Stage"
          key="stage"
          :disabled="isCategorySelected"
          v-loading="loaders.modal"
        >
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <form role="form" @submit.prevent="handleSubmit(onSubmit)">

              <el-checkbox-group
                v-model="api_param.stages"
              >
                <div class="stage_box pt-3 pl-3 d-inline-block">
                  <el-checkbox :label="25">Considering Surgery</el-checkbox>
                </div>

                <div class="stage_box pt-3 pl-3 ml-2 d-inline-block">
                  <el-checkbox :label="50">Pre-Op</el-checkbox>
                </div>

                <div class="stage_box pt-3 pl-3 d-inline-block mt-2">
                  <el-checkbox :label="75">Post-Op</el-checkbox>
                </div>

                <div class="stage_box pt-3 pl-3 ml-2 d-inline-block mt-2">
                  <el-checkbox :label="150">Non-Surgical</el-checkbox>
                </div>
              </el-checkbox-group>

              <div class="mt-3">
                <base-input placeholder="Message" name="message" :rules="{required: true}" label="Message:">
                  <textarea v-model="api_param.message" class="form-control" rows="3" resize="none"></textarea>
                </base-input>
              </div>

              <div class="text-right mt-4">
                <el-button class="cancel-btn" @click="closeMessageDialog">Cancel</el-button>
                <el-button class="move_btn" :disabled="loaders.modal" native-type="submit" type="submit">Send Message
                </el-button>
              </div>

            </form>
          </validation-observer>
        </el-tab-pane>

        <el-tab-pane
          label="Patient Tags"
          key="category"
          :disabled="categoriesEmpty || isStageSelected"
          v-loading="loaders.modal"
        >
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <form role="form" @submit.prevent="handleSubmit(onSubmit)">

              <div class="scrollable vh-35">

                <template v-for="(category,index) in categories">
                  <h4 class="tabs-segment-text pt-2 w-85 ellipsis-overflow-text"> {{ category.name }}</h4>
                  <template v-if="!subCategoriesEmpty(index)">
                    <el-checkbox-group
                      :key="category.id"
                      v-model="api_param.subCategories[category.id]"
                    >
                      <template v-for="(subCategory) in category.user_sub_categories">
                        <div class="stage_box pt-3 my-1 mx-1 pl-3 d-inline-block ellipsis-overflow-text">
                          <el-checkbox :label="subCategory.id"
                                       :key="subCategory.id"
                                       class=""
                          >
                            <span>{{ subCategory.name }}</span>
                          </el-checkbox>
                        </div>
                      </template>
                    </el-checkbox-group>
                  </template>
                  <div v-else>
                    <h3 class="text-center"> No {{ category.name }} Available </h3>
                  </div>
                </template>

              </div>

              <div class="mt-3">
                <base-input placeholder="Message" name="message" :rules="{required: true}" label="Message:">
                  <textarea v-model="api_param.message" class="form-control" rows="3" resize="none"></textarea>
                </base-input>
              </div>

              <div class="text-right mt-4">
                <el-button class="cancel-btn" @click="closeMessageDialog">Cancel</el-button>
                <el-button class="move_btn" :disabled="loaders.modal" native-type="submit" type="submit">Send Message
                </el-button>
              </div>

            </form>
          </validation-observer>
        </el-tab-pane>
      </el-tabs>

    </el-dialog>
  </div>
</template>

<script>
import authHelper from "@/util/authHelper";

export default {
  props: ['dialogVisible', 'categories'],
  created() {
    this.addCategoryToApi()
  },
  watch: {
    categories(newVal) {
      this.categories = newVal
      this.addCategoryToApi()
    }
  },
  data() {
    return {
      loaders: {
        modal: false
      },
      api_param: {
        message: '',
        requestTab: 'stage',
        stages: [],
        subCategories: {}
      }
    };
  },
  computed: {
    categoriesEmpty() {
      return (typeof this.categories === 'undefined') ? true : this.categories.length === 0;
    },
    isStageSelected() {
      return this.api_param.stages.length > 0
    },
    isCategorySelected() {
      for (const argument of Object.keys(this.api_param.subCategories)) {
        if (this.api_param.subCategories[argument].length > 0) return true
      }
      return false
    }
  },
  methods: {
    subCategoriesEmpty(index) {
      return (this.categoriesEmpty) || (typeof this.categories[index].user_sub_categories === 'undefined') ?
        true : this.categories[index].user_sub_categories.length <= 0;
    },
    clearSubCategories() {
      const keys = Object.keys(this.api_param.subCategories)
      for (const key of keys) {
        this.api_param.subCategories[key] = [];
      }
    },
    onSubmit() {
      if ((this.api_param.message !== '') &&
        (this.isStageSelected || this.isCategorySelected)) {
        this.submitRequest()
      }
    },
    handleClose(done) {
      this.$emit('closeDialog')
    },
    handleTabClick(object) {
      this.api_param.requestTab = object.$vnode.key
    },
    addCategoryToApi() {
      if (!this.categoriesEmpty) {
        for (let i = 0; i < this.categories.length; i++)
          this.$set(this.api_param.subCategories, `${this.categories[i].id}`, [])
      }
    },
    closeMessageDialog() {
      (this.$route.name === 'OnDemand Messages') ? this.$emit('updateMessages') : this.$emit('closeDialog');
    },
    submitRequest() {
      const th = this;
      th.loaders.modal = true
      const payload = {
        message: th.api_param.message,
        requestTab: th.api_param.requestTab,
      }
      Object.assign(payload, (th.api_param.requestTab === 'stage') ?
        {stages: th.api_param.stages} : {subCategories: th.api_param.subCategories});
      const response = (th.$route.name === 'OnDemand Messages') ? th.$store.dispatch('_storeOnDemandMessage', payload)
        : axios.post(this.$store.getters.getBaseUrl + '/api/group-message', payload);
      response.then((response) => {
        th.$store.commit('toggleSessionCheck', true)
        th.$store.commit('updateSessionTime', 0)
        th.api_param.message = '';
        this.clearSubCategories()
        this.api_param.stages = []
        th.$notify.success({
          title: 'Success',
          message: 'Message will be sent out shortly.'
        });
        th.closeMessageDialog()
        }).catch(error => {
        const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        th.loader = false;
        th.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => th.loaders.modal = false)
    }
  }
};
</script>

<style scoped>
.stage_box {
  width: 225px;
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

.move_btn {
  top: 505px;
  left: 850px;
  width: 130px;
  height: 45px;
  background: #832C44 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  border: 0px;
  color: white;
  opacity: 1;
}

.cancel-btn {
  top: 505px;
  left: 756px;
  width: 79px;
  height: 45px;
  background: #8898AA 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border: 0px;
  border-radius: 6px;
  color: white;
  opacity: 1;
}

.tabs-segment-text {
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: hsl(218, 54%, 20%);
}

.scrollable {
  overflow-y: auto;
}

.vh-35 {
  height: 35vh;
  min-height: 120px;
}

</style>
<style>
.dialog-tabs > .el-tabs__header {
  background-color: white !important;
}

.dialog-tabs .el-tabs__nav {
  width: 100%;
  border: 0 !important;
}

.dialog-tabs .el-tabs__item {
  font-weight: bold;
  width: 50%;
  text-align: center;
  color: hsl(218, 54%, 20%) !important;
  border-radius: 0 !important;
  border-bottom: 1px solid hsl(210, 16%, 93%) !important;

}

.dialog-tabs .el-tabs__item:first-child {
  border-top-left-radius: 8px !important;
}

.dialog-tabs .el-tabs__item:last-child {
  border-top-right-radius: 8px !important;
}

.dialog-tabs .el-tabs__item.is-active {
  color: #832C44 !important;
  border: 1px solid hsl(210, 16%, 93%) !important;
  border-bottom: 0 !important;
}

.dialog-tabs .el-tabs__item.is-disabled {
  color: hsl(210, 14%, 75%) !important;
}

</style>
